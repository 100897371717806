.intro-content {
    padding: 1rem;
}
.intro-content p {
    text-align: left;
}
.intro-content p:last-child {
    text-align: right;
}

.intro-content p:last-child span {
    color: black;
}
.intro-content br {
    display: initial;
}