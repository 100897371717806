.schools {
    padding: 1rem;
    text-align: left;
}
.schoolList {
    padding: 0;
}
.schoolList li {
    list-style: none;
    text-align: left;
    padding-top: 0.6em;
    display: flex;
    justify-content: space-between;
}
.schoolList li a{
    padding-left: 0.5rem;
}
.schools .kokia-section-title {
    border: 0;
}
.school-impression .accordion {
    margin: 0;
}
.school-impression .item-header .title {
    font-weight: 500;
    color: var(--kokia-red-light)
}