.login-container {
    width: 14rem;
    margin: auto;
    display:flex;
    flex-direction: column;
    padding: 13rem 0;
    justify-content: center;
}

.progress-bar {
    height: 4px;
    background-color: var(--kokia-bg-color);
    width: 100%;
    overflow: hidden;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: var(--kokia-red);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }   

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}