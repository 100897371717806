.achievement-list {
    margin: auto;
    padding: 1rem;
    width: fit-content;
}

.ach-header {
    text-align: left;
    color: black;
    font-size: 105%;
    padding-bottom: 0.5em;
}

.achievement-list .ach-remark {
    text-align: left;
    font-size: 75%;
    padding-top: 0.6em;
}
.achievement-list .region {
    padding: 0.6em 0;
    font-weight: bold;
}

.achievement-list .item {
    display: grid;
    grid-template-columns: 1fr 4em;
    width: 21em;
    text-align: center;
    margin-left: 1em;
}
.achievement-list .item div:first-child {
    text-align: left;
}

.accordion {
    width: 100%;
}
.accordion-body {
    padding: 0;
}