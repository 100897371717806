/* app-logo component*/
.app-logo {
  display: block;
  height: 128px;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-image:
          url("/public/welcome-image.jpg"),
          linear-gradient(to left, var(--kokia-bg-color) 3%, #dc3545 calc((100vw - 900px) / 2), #dc3545 calc((100vw + 900px) / 2), var(--kokia-bg-color) 97%);
  padding: 30px calc((100vw - 50em) / 2) 0;
  font-weight: 600;
  font-size: 11px;
  color: white;
}

.app-logo-title {
  color: var(--kokia-red);
  padding: 1em;
  font-size: 150%;
  font-family: serif;
  text-shadow: #dc3545 2px 0 10px;
  background: radial-gradient(white 45%, rgba(255, 255, 255, 0.8) 53%, #0000 70%);
}

@media (orientation: portrait) {
  .app-logo {
    padding: 30px 0;
  }
  .app-logo-title br {
    display: unset;
  }
}

/* welcome page special: no kokia image */
.page-bg:has(* .app-logo) {
  background-image: unset;
  background-color: var(--kokia-bg-color);
}

.welcome-container {
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 32rem;
  text-align: left;
  border-radius: var(--bs-border-radius);
  background-color: var(--kokia-bg-color);
  margin-top: 0.5rem;
}
@media (orientation: portrait) {
  .welcome-container {
    width: 100vw;
    max-width: 22rem;
  }
}

.welcome-container ul,
.welcome-container span,
.welcome-container p {
  font-size: 90%;
}

.welcome-container ul {
  text-align: left;
  margin-left: -1rem;
  margin-bottom: 0; 
}
