.paging a {
    margin: 0.5em 0.1em;
    padding: 0.5em;
    background: rgba(255, 0, 0, 0.129);
}
.paging a.active {
    text-decoration: underline;
}
.userList {
    width: 100%
}
.userList li {
    list-style: none;
    text-align: left;
    padding-top: 0.6em;
    display: grid;
    grid-template-columns: 3em 1fr 4em 6em;
    gap: 0.3em;
    align-items: center;
}
.userList img {
    width: 3em;
    border-radius: 0.7em;
}