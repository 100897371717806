body {
    padding-top: 80px;
}
.navbar {
    padding: 0;
}
.navbar-brand {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.nav-link {
  color: var(--kokia-red-light) !important;
  padding-left: 1rem;
}
.nav-link img {
  margin: -8px 0.5rem -4px 0;
}
.navbar-nav {
  background-color: var(--kokia-bg-color) !important;
  border: 2px solid pink;
  border-radius: 5px;
  width: 10rem;
  display: inline-block;
  text-align: left;
}
.navbar-toggler {
  margin-right: 5px;
  &:focus {
    box-shadow: unset;
  }
}
.navbar-collapse {
  text-align: right;
  margin-right: 5px;
  
}
.navbar-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  z-index: -1;
  background-color: var(--kokia-bg-color) !important;
}
@media (min-width: 768px) {
  .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-nav {
      border: 0;
      width: unset;
      display: flex;
      justify-content: flex-end;
  }
  .navbar-brand {
    margin-left: calc((100vw - 320px) / 2);
  }
}
/* smaller image on tall viewport */
@media (max-width: 390px) {
  .navbar-brand img{
    width: 280px;
  }
  .navbar-bg-overlay {
    height: 70px;
  }
  body {
    padding-top: 70px;
  }
}
/* even smaller image on foldable viewport */
@media (max-width: 350px) {
  .navbar-toggler {
    padding: 3px;
  }
  .navbar-brand img{
    width: 220px;
  }
  .navbar-bg-overlay {
    height: 55px;
  }
  body {
    padding-top: 55px;
    font-size: 70%;
  }
}

.footer {
    margin: 0;
    padding-bottom: 1em;
    text-align: center;
    
}
.footer .row > * {
    padding-right: 0;
}
.footer p {
    margin: 0;
    line-height: 1.2em;
}
.footer p:nth-child(2) {
    color: #36454F;
    font-size: 90%;
    letter-spacing: 0.15em;
}
.footer hr {
    margin: 0.5em 1em;
}

/* kokia image and gradient background */
.page-bg {
    color: var(--kokia-red);
    min-height:85px;
    text-align: center;
    background:
        linear-gradient(#ffffff00 0%, var(--kokia-bg-color) 1280px),
        url('/public/28162782_l.jpg') top no-repeat;
}
/* responsive page width for the actual content */
.page-content {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  align-items: center;
  width: calc(100% - 2em);
  min-height: 90vh;
  margin: auto;
  color: var(--graphite);
}

@media (orientation: landscape) or (min-width: 42em) {
  .page-content {
      width: 32em;
  }
}

/* Welcome menu buttons */
.menu-container {
    display: flex;
    justify-content: center;
    column-gap: 0.5em;
    flex-wrap: wrap;
    margin: 1rem 0;
    background-color: #ffffff00;
  }
  
  .menu-container .button.short {
    letter-spacing: 0.3em;
    padding-left: 0.2em;
    padding-right: 0em;
  }
  
  .menu-container .button {
    width: 6em;
  }

  .menu-container .button.shop {
    letter-spacing: -0.2em;
    padding-left: 0em;
    padding-right: 0.2em;
    box-shadow: 0px 0px 5px mediumvioletred;
    border: 0.1em rgb(255, 178, 227) solid;
  }
  
  @media (orientation: portrait) {
    .menu-container .button {
      font-size: 95%;
      letter-spacing: 0.1em;
      width: 4em;
      height: 4em;
    }
  
    .menu-container .button.short a {
      font-size: 90%;
    }
  }