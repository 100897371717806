:root {
  --kokia-red: #832731;
  --kokia-red-light: #DC3545;
  --kokia-bg-color: #fff2f2;
  --graphite: #383428;
  --bs-body-bg: var(--kokia-bg-color);
  --bs-body-color: var(--graphite);
  --bs-primary-bg-subtle: #ffd4d4e3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--kokia-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:link, a:visited {
  color: var(--kokia-red-light);
  text-shadow: none;
  text-decoration: none;
}
a:hover {
  text-shadow: #ff6b7a6a 0 0 2px;
  filter: drop-shadow(0 0 2px #ff6b7a);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7em;
  background: var(--kokia-red-light);
  color: var(--kokia-bg-color);
  padding: 0.6em;
}

.button a {
  color: var(--kokia-bg-color);
  text-decoration: none;
}
.button a:link,
.button a:visited {
  color: var(--kokia-bg-color);
}
.button a:hover {
  color: var(--kokia-bg-color);
  text-decoration: underline;
}

.kokia-watermark {
  background-image: url("/public/kokia-stencil.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

/* <br /> is used for conditional linebreaks in portrait orientation */
br {
  display: none;
}
@media (orientation: portrait) {
  br {
    display: unset;
  }
}