.kokia-news-container {
    padding-top: 0.3rem;
}
.kokia-news-container .news-items {
    padding-top: 0.2rem;
}

.kokia-news-item {
    margin-bottom: 0.5em;
    text-align: left;
    display: grid;
    grid-template-columns: 5em 1fr;
}
.kokia-news-item div:first-child {
    color: #e17f87;
    font-size: 80%;
}
.kokia-news-item p {
    margin: 0;
}