.kokia-section-title {
    display: grid;
    grid-template-columns: 2em 1fr 8em;
    border-bottom: 2px dotted;
    margin-bottom: 0.3em;
    color: var(--kokia-red);
}
/* section title */
.kokia-section-title div:nth-child(2) {
    text-align: left;
    padding-top: 0.2em;
    padding-left: 1em;
    text-shadow: var(--kokia-red-light) 2px 0 10px;
}
/* optional link */
.kokia-section-title div:last-child {
    width: 8em;
    padding-top: 0.2em;
    text-shadow: unset;
    text-align: right;
}

.kokia-section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 32rem;
    border-radius: var(--bs-border-radius);
    background-color: var(--kokia-bg-color);
}

/* page title*/
.page-title-content {
    width: 100%;
    display: grid;
    margin: 1.5rem 0;
    grid-template-columns: 2em 2em 2em 1fr 2em 2em 2em;
    color: var(--kokia-red);
    border-bottom: 2px dotted;
    align-items: self-end;
}
.page-title-content .page-title-text {
    font-size: 140%;
    text-align: center;
    margin-top: -0.2em;
}
.page-title-content div:first-child,
.page-title-content div:last-child {
    width: 24px;
    height: 24px;
}
.page-title-content div:nth-child(2),
.page-title-content div:nth-last-child(2) {
    width: 28px;
    height: 28px;
}

.page-title-content.short {
    display: flex;
    justify-content: flex-start;
}
.page-title-content.short .page-title-text{
    margin: 0 auto;
    font-size: 120%;
}
.page-title-content.short > .kokia-watermark {
    display: none;
}

/* page section title*/
.kokia-page-section-title {
    width: 100%;
    display: flex;
}
.kokia-page-section-title .illust {
    background-image: url('/public/feather-graphite.png');
    background-size: contain;
    background-position-x: center;
    background-position-y: 5px;
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    filter: drop-shadow(1px 1px var(--kokia-bg-color));
}
.kokia-page-section-title .label {
    font-size: 120%;  
    line-height: 2em;
    font-weight: bold;
    text-shadow: 2px 2px 2px var(--kokia-bg-color);
}