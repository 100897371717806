.shop-item-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}
.shop-item-header .title {
    display: block;
    font-size: 110%;
    font-weight: bold;
    color: var(--kokia-red-light)
}
.shop-item-header * {
    width: 100%;
}

.shop-content br {
    display: unset;
}

.shop-content p {
    margin-bottom: 0;
}

.shop-content p + p {
    margin-top: 1em;
}
.shop-content li:last-child {
    margin-bottom: 0rem;
}

.shop-content li {
    margin-bottom: 0.5rem;
}
.shop-content ol ~ p ~ ol {
    margin-bottom: 0em;
}
.accordion {
    margin-bottom: 2rem;
    text-align: left;
}
.accordion-button { 
    background-color: unset;
}
.accordion-button:focus {
    border-color: unset;
    box-shadow: unset;
}
.accordion-button:not(.collapsed) {
    color: var(--graphite)
}

@media (orientation: portrait) or (max-width: 42em) {
    .accordion-body { 
        padding: 0.2rem
    }
    .accordion-button { 
        padding: 0.5rem
    }
}

/* disable accordion without content */
.accordion-header.hide-button .accordion-button::after {
    display: none;
}
.accordion-header.hide-button .accordion-button {
    background: unset;
    cursor: initial;
}